import React from 'react';
import { Col, Row, Button, Image } from 'react-bootstrap';
import nftmoveone from "../../../../assets/images/nftmove-one.png";
import nftmovetwo from "../../../../assets/images/nftmove-two.png";
import nftmovethree from "../../../../assets/images/nftmove-three.png";

const IndependentNft = () => {
    return (
        <div className='independentnft'>
            <div className='container-width'>
                <Row>
                    <Col lg="6">
                        <div className='independentnft-content text-lg-start text-center'>
                            <h3 className='text-uppercase'>Maximize your odds... and the prize pool</h3>
                            <p className='mb-4'>Each NFT entered is an opportunity to win... and 60% of every entry goes directly into the prize pool awaiting the victor. The more you enter, the better your chances of one of your NFTs being the champion, and the larger the prize for you to claim! Control your horde one at a time for a surgical approach, or in bulk to overwhelm the opposition.</p>
                            <div className='independentnft-buttons d-flex flex-lg-row flex-md-row flex-sm-row flex-column justify-content-lg-start justify-content-center'>
                                <Button className='btn-purple me-lg-4 me-md-4 me-sm-4 me-0 mb-lg-0 mb-md-0 mb-sm-0 mb-4'>PLAY THE GAME</Button>
                            </div>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className='independentnft-content-images position-relative'>
                            <div className='independentnft-image-one position-absolute'>
                                <Image src={nftmoveone} fluid/>
                            </div>
                            <div className='two-images-wrapper position-relative d-flex justify-content-between'>
                                <div className='independentnft-image-two'>
                                    <Image src={nftmovetwo} fluid/>
                                </div>
                                <div className='independentnft-image-three'>
                                    <Image src={nftmovethree} fluid/>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default IndependentNft
