import React from 'react'
import { Col, Row, Image, Button } from 'react-bootstrap';
import hoursmoveimg from "../../../assets/images/24-hours-img.svg";

const HoursMove = () => {
    return (
        <div className='hours-move'>
            <div className='container-width'>
                <Row>
                    <Col lg='7'>
                        <div className='hours-move-image mb-lg-0 mb-5' data-aos="zoom-in-right" data-aos-duration="1500">
                            <Image src={hoursmoveimg} fluid/>
                        </div>
                    </Col>
                    <Col lg='5'>
                        <div className='hours-move-content text-lg-start text-center' data-aos="zoom-in-left" data-aos-duration="1500">
                            <h3 className='text-uppercase'>Come back every day to play</h3>
                            <h4>START AND TRACK A 24-HOUR COUNTDOWN</h4>
                            <p>Each day, you may move each of your NFTs forward along the Bridge of Fates. At noon Eastern Time, the Demons will reveal which side of the Bridge is safe, and which NFTs have met their untimely end. But don't worry, you can always make a deal with them to buy another chance at the prize.</p>
                            <Button className='btn-purple'>PLAY THE GAME</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default HoursMove
