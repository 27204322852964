import React from 'react'
// import { Container } from 'react-bootstrap'


const HowToPlay = () => {
    return (
        <>

            <div className='content-page-wrapper'>
                <div className='container-width'>
                    <div className='title'>
                        <h1>How to play bridge of fates</h1>
                    </div>
                    <div className='content'>
                        <p>Your objective is to cross the unstable Bridge of Fates before other challengers and claim your prize. Each day, you can elect to move each of your NFTs individually. You can choose to play it safe and stand still or bravely forge ahead to the next level on the bridge, to the left or right. When the 24-hour countdown expires, the safe and unsafe sides of the bridge will be revealed. The NFTs on the safe side of the bridge will remain in the game while those that moved to the unsafe side will be eliminated. A new timer will begin, and the process will repeat. The players that played safe and hung back will have the safe side of the bridge revealed to them and they can move up to join the pioneers. Those pioneers can again forge forward, taking advantage of their continued bravery. Each NFT may only move up 1 level per day, so playing it safe means falling behind. Only the NFTs that reach the end of the bridge first will claim the prize, so it pays to be adventurous! If your NFT is eliminated, you will have the opportunity to buy back in at the level you jumped from for a fee that increases with each level.</p>
                        <p>The prize pool will be generated from entry fees and buy back fees. Series 1 Ethnology Cyberpunk NFTs enter Bridge of Fates for Free while Series 2 Gods and Demons NFTs enter for 0.01Eth each. Winning NFTs will split the prize. The prize pool will be funded as follows.</p>
                        <h2>Rulesets</h2>
                        <p>Each time that Bridge of Fates is played, a new rule will be added so that the game is always fresh. In the first round, the Bridge will have a set length of 13 tiles. Who knows what future madness the Gods and Demons have in store?</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HowToPlay
