import React from 'react'
import Banner from './Banner/Banner'

const BannerSideWrapper = () => {
    return (
        <div className='section-wrapper'>
           
            <Banner/>
        </div>
    )
}

export default BannerSideWrapper
