import React from 'react'
import { Button, Row, Col } from 'react-bootstrap'

const Banner = () => {
    return (
        <div className='banner'>
            <div className='container-width'>
                <Row>
                    <Col lg='6'>
                        <div className='banner-content text-lg-start text-center'>
                            <h2 className='mb-1 text-uppercase'>BRIDGE OF FATES</h2>
                            <h4>Play and Earn Huge Prizes</h4>
                            <p data-aos="fade-right" data-aos-duration="1500" data-aos-delay="3000">Test your nerve against the Ethnology community in a game of wits, patience, and fortune! A divine prize awaits you... if you can navigate the perilous Bridge of Fates. Watch your step, as every move forward towards the treasure carries a risk of falling into the Demon's pit. You must own an Ethnology NFT to enter.</p>
                            <Button className='btn-purple'>PLAY THE GAME</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Banner
