import React from 'react';
import { Image } from 'react-bootstrap';
import logo from "../../assets/images/logo.svg";
import Lottie from 'react-lottie';
// import walletlottie from '../../assets/Lottie/wallet.json';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Container, Nav, Navbar, Dropdown } from 'react-bootstrap';
import homelottie from '../../assets/Lottie/home.json';
import playlottie from '../../assets/Lottie/play.json';
import graphlottie from '../../assets/Lottie/graph.json';
import exclamlottie from '../../assets/Lottie/exclamation.json';
import { Link } from 'react-router-dom';

const Header = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: homelottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const defaultOptionstwo = {
        loop: true,
        autoplay: true,
        animationData: playlottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const defaultOptionsthree = {
        loop: true,
        autoplay: true,
        animationData: graphlottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const defaultOptionsfour = {
        loop: true,
        autoplay: true,
        animationData: exclamlottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className='header'>

            <Navbar expand="lg" className="">
                <Container fluid>
                    <Link to="/" className="navbar-brand logo">
                        <Image src={logo} fluid />
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Link to="/" className='nav-link'>
                                <div className='sidebar-lottie'>
                                    <Lottie
                                        className="sidebar-lottie-icon"
                                        options={defaultOptions}
                                    />
                                </div>Home</Link>
                            <Link to="/how-to-play" className='nav-link'>
                                <div className='sidebar-lottie'>
                                    <Lottie
                                        className="sidebar-lottie-icon"
                                        options={defaultOptionstwo}
                                    />
                                </div>How to play</Link>
                            <Dropdown className='nav-link'>
                                <Dropdown.Toggle id="dropdown-basic" className=''>
                                    <div className='sidebar-lottie'>
                                        <Lottie
                                            className="sidebar-lottie-icon"
                                            options={defaultOptionsthree}
                                        />
                                    </div> Market
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="https://opensea.io/collection/ethnology-series-1" target="_blank">Series 1</Dropdown.Item>
                                    <Dropdown.Item href="https://opensea.io/collection/ethnology-s2-gods-and-demons" target="_blank">Series 2</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to="#home" className='nav-link'>
                                <div className='sidebar-lottie'>
                                    <Lottie
                                        className="sidebar-lottie-icon"
                                        options={defaultOptionsfour}
                                    />
                                </div>About</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Header
