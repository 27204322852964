import React from 'react';
import { Image } from 'react-bootstrap';
import playgameimg from "../../../assets/images/play-game-img.png";

const WinPrize = () => {
    return (
        <div className='win-prize'>
            <div className='container-width'>
                <div className='win-prize-content text-center'>
                    <h1 className='mb-4'>WIN BIG PRIZE</h1>
                    <a href="/">
                        <div className='win-prize-img'>
                            <Image src={playgameimg} fluid />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default WinPrize
