import React, {useEffect} from 'react'
import { Col, Row, Button, Image } from 'react-bootstrap';
import entriesimageone from "../../../../assets/images/entries-one.png";
import entriesimagetwo from "../../../../assets/images/entries-two.png";
import entriesimagethree from "../../../../assets/images/entries-three.png";
import AOS from "aos";

const Entries = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return (
        <div className='entries'>
            <div className='container-width'>
                <Row>
                    <Col lg="6">
                        <div className='entries-images-wrapper position-relative'>
                            <div className='entries-images-one position-relative' data-aos="fade-right" data-aos-duration="1500">
                                <Image src={entriesimageone} fluid />
                            </div>
                            <div className='entries-images-two position-absolute' data-aos="fade-up-left" data-aos-duration="1500">
                                <Image src={entriesimagetwo} fluid />
                            </div>
                            <div className='entries-images-three position-absolute' data-aos="fade-down-left" data-aos-duration="1500">
                                <Image src={entriesimagethree} fluid />
                            </div>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className='entries-content text-lg-start text-center'>
                            <h3 className='text-uppercase'>Enter with your Ethnology NFTs</h3>
                            <p>Enter with your <a href="https://opensea.io/collection/ethnology-series-1" target='_blank' rel="noreferrer">Series 1: Cyberpunk</a>, or <a href="https://opensea.io/collection/ethnology-s2-gods-and-demons" target='_blank' rel="noreferrer">Series 2: Gods and Demons</a> Ethnology NFTs. As our founders series NFTs, Cyberpunk NFTs enter Bridge of Fates for free! Entry for Gods and Demons NFTs is 0.01Eth.</p>
                            <div className='entries-buttons d-flex flex-lg-row flex-md-row flex-sm-row flex-column justify-content-lg-start justify-content-center'>
                                <Button className='btn-purple me-lg-4 me-md-4 me-sm-4 me-0 mb-lg-0 mb-md-0 mb-sm-0 mb-4'>PLAY THE GAME</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Entries
