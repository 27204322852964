import React from 'react'
import Entries from './Entries/Entries'
import IndependentNft from './IndependentNft/IndependentNft'

const SectionEntriesWrapper = () => {
    return (
        <div className='section-entries-wrapper'>
            <Entries/>
            <IndependentNft/>
        </div>
    )
}

export default SectionEntriesWrapper;
