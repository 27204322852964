import React from 'react'
import BannerSideWrapper from './BannerSideWrapper/BannerSideWrapper';
import HoursMove from './HoursMove/HoursMove';
import SectionEntriesWrapper from './SectionEntriesWrapper/SectionEntriesWrapper'
import WinPrize from './WinPrize/WinPrize';
// import Sidebar from '../Sidebar/Sidebar'


const HomePage = () => {
    return (
        <>
          
            {/* <Sidebar/> */}
                <BannerSideWrapper />
                <SectionEntriesWrapper />
                <HoursMove />
                <WinPrize />
        </>
    )
}

export default HomePage
