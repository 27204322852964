import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import HomePage from './components/HomePage/HomePage';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HowToPlay from "./components/HowToPlay/HowToPlay";

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Header />
				<div className='wrapper'>
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="/how-to-play" element={<HowToPlay />} />
					</Routes>
					<Footer />
				</div>

			</BrowserRouter>

		</div>
	);
}

export default App;
