import React from 'react'
import { Row, Col, Image } from 'react-bootstrap';
import footerlogo from "../../assets/images/footer-logo.svg";
import facebook from "../../assets/images/facebook.png";
import twitter from "../../assets/images/twitter.png";
import insta from "../../assets/images/insta.png";
import youtube from "../../assets/images/youtube.png";
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <div className='footer'>
            <div className='container-width'>
                <Row>
                    <Col lg="5">
                        <div className='footer-logo-content'>
                            <a href='/'>
                                <div className='logo'>
                                    <Image src={footerlogo} fluid />
                                </div>
                            </a>
                            <p>Human decision has shaped history as we know it since our beginnings. For better or for worse, we are burdened by the many paths laid before us. Life has never been and will never be a straightforward journey. Will you take the risk and find the right path? Watch your step.</p>
                        </div>
                    </Col>
                    <Col lg="7">
                        <Row>
                            {/* <Col lg="4" md="6" className='mb-lg-0 mb-4'>
                                <div className='footer-logo-content-wrapper'>
                                    <h4>Explore</h4>
                                    <ul className='p-0 m-0 footer-list'>
                                        <li><a href='/'>Pricing</a></li>
                                        <li><a href='/'>Components</a></li>
                                        <li><a href='/'>How It Works</a></li>
                                        <li><a href='/'>Road Map</a></li>
                                    </ul>
                                </div>
                            </Col> */}
                            <Col lg="8" md="6" className='mb-lg-0 mb-4'>
                                <div className='footer-logo-content-wrapper'>
                                    <h4>Helpful Links</h4>
                                    <ul className='p-0 m-0 footer-list'>
                                        <li><Link to='/'>About us</Link></li>
                                        <li><Link to='/'>Marketplace</Link></li>
                                        <li><Link to='/how-to-play'>How to Play</Link></li>
                                        <li><Link to='/'>Privacy Policy</Link></li>
                                        <li><Link to='/'>Terms of Use</Link></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg="4" md="6" className='mb-lg-0 mb-4'>
                                <div className='footer-logo-content-wrapper'>
                                    <h4 className='mb-4'>Social Links</h4>
                                    <div className='social-icons d-flex justify-content-between mb-5'>
                                        <a href='/'>
                                            <div className='social-icons-img'>
                                                <Image src={facebook} fluid />
                                            </div>
                                        </a>
                                        <a href='/'>
                                            <div className='social-icons-img'>
                                                <Image src={twitter} fluid />
                                            </div>
                                        </a>
                                        <a href='/'>
                                            <div className='social-icons-img'>
                                                <Image src={insta} fluid />
                                            </div>
                                        </a>
                                        <a href='/'>
                                            <div className='social-icons-img'>
                                                <Image src={youtube} fluid />
                                            </div>
                                        </a>
                                    </div>
                                    {/* <Button className='btn-purple'>JOIN OUR COMMUNITY</Button> */}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className='copyright text-center'>
                    <p>© 2023 Ethnology LLC. All Rights Reserved</p>
                </div>
            </div>
        </div>
    )
}

export default Footer
